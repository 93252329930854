import React  from 'react';
import './HistoryPost.css';

import { saveAs } from 'file-saver';

class Post extends React.Component {

constructor(props)
{
  super(props);
  this.state = {
    mobileNumber: this.props.mobileNumber,
    filename: this.props.name,
  }
}

    render()
    {
    return (
        <div className="postWaiting">
            
            <div className="postBottom">
                <p>{this.props.mobileNumber}</p>
            </div>

            <button onClick={() => saveAs(this.state.filename, this.state.filename.substring(this.state.filename.lastIndexOf('/')+1))}> PDF`i yüklə</button>

        </div>
    )
}
}

export default Post;
